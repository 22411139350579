/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';
import { CustomAttributeIdentifier } from '@ws1c/intelligence-models/filter/custom-attribute-identifier.model';

/**
 * CustomAttributeIdentifiersSearchResponse
 * @export
 * @class CustomAttributeIdentifiersSearchResponse
 * @implements {PagedResponse}
 */
@Serializable
export class CustomAttributeIdentifiersSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: CustomAttributeIdentifier })
  public results: CustomAttributeIdentifier[] = [];

  /**
   * Creates an instance of CustomAttributeIdentifiersSearchResponse
   * @param {Array<Partial<CustomAttributeIdentifiersSearchResponse>>} args
   * @memberOf CustomAttributeIdentifiersSearchResponse
   */
  constructor(...args: Array<Partial<CustomAttributeIdentifiersSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
