/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, SortOn, TrendSpan } from '@dpa/ui-common';
import * as shape from 'd3-shape';

import { AppDetailTabType } from '@ws1c/intelligence-models/app-loads/app-detail-tab-name.enum';
import { AppErrorType } from '@ws1c/intelligence-models/app-loads/app-error-type.enum';
import { AppPlatform } from '@ws1c/intelligence-models/app-loads/app-platform.model';
import {
  AUTOMATION_ERROR_STATUS,
  AUTOMATION_PENDING_STATUS,
  AUTOMATION_SUCCESS_STATUS,
} from '@ws1c/intelligence-models/automation/automation-run-history-state.constant';
import {
  AggregationFunction,
  AggregationWidgetChartType,
  ComposeFunction,
  StandardDashboardType,
  StandardWidgetSubtype,
} from '@ws1c/intelligence-models/dashboard/dashboard.enum';
import { Entity } from '@ws1c/intelligence-models/entity.model';
import { FilterRule } from '@ws1c/intelligence-models/filter/filter-rule.model';
import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { DataType } from '@ws1c/intelligence-models/integration-meta/data-type.model';
import { Integration } from '@ws1c/intelligence-models/integration.model';
import { RatioIndicatorColors } from '@ws1c/intelligence-models/ratio-indicator-colors.enum';
import { getUniqueId } from '@ws1c/intelligence-models/utils/get-unique-id';
import { ChronoUnit } from './chrono-unit.enum';
import { DashboardColors } from './dashboard-color.enum';
import { DeemSolutionDashboardConfig } from './deem-solution-dashboard.config';
import { DevicesDashboardConfig } from './devices-dashboard.config';
import { HorizonDashboardConfig } from './horizon-dashboard.config';
import { ComposeConfigIndex } from './trend-composer.interface';
import { TrendDateRange } from './trend-date-range.model';
import { UserProfileDashboardConfig } from './user-profile-dashboard.config';

export interface CvssLabelThreshold {
  labelKey: string;
  baseThreshold: number;
  badgeClass: string;
}

/**
 * Define all common configs to be used with dashboard
 * @export
 * @class DashboardConfig
 */
export class DashboardConfig {
  public static Integration: string = 'integration';
  public static Entity: string = 'entity';

  // See https://swimlane.gitbook.io/ngx-charts/examples/line-area-charts/line-chart
  // chartCurveTypes map d3.curve function to the interpolation function used to generate the curve in LINE/AREA charts
  public static readonly chartCurveTypes = {
    Basis: shape.curveBasis,
    BasisClosed: shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    CardinalClosed: shape.curveCardinalClosed,
    CatmullRom: shape.curveCatmullRom,
    CatmullRomClosed: shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    LinearClosed: shape.curveLinearClosed,
    MonotoneX: shape.curveMonotoneX,
    MonotoneY: shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    StepAfter: shape.curveStepAfter,
    StepBefore: shape.curveStepBefore,
    Default: shape.curveLinear,
  };

  // Device Platform
  public static readonly DESKTOP_OS = [AppPlatform.WINDOWS_DESKTOP, AppPlatform.APPLE_MACOS];
  public static readonly MOBILE_OS = [AppPlatform.ANDROID, AppPlatform.APPLE_IOS];

  public static chartTypesByTrendMode = {
    HISTORICAL: [
      AggregationWidgetChartType.VERTICAL,
      AggregationWidgetChartType.VERTICAL_GROUP,
      AggregationWidgetChartType.LINE,
      AggregationWidgetChartType.AREA,
      AggregationWidgetChartType.METRIC,
      AggregationWidgetChartType.TABLE,
      AggregationWidgetChartType.HEAT_MAP,
    ],
    SNAPSHOT: [
      AggregationWidgetChartType.HORIZONTAL,
      AggregationWidgetChartType.HORIZONTAL_GROUP,
      AggregationWidgetChartType.VERTICAL,
      AggregationWidgetChartType.VERTICAL_GROUP,
      AggregationWidgetChartType.DONUT,
      AggregationWidgetChartType.METRIC,
      AggregationWidgetChartType.TABLE,
      AggregationWidgetChartType.TREE_MAP,
    ],
    SNAPSHOT_PERIODICAL: [
      AggregationWidgetChartType.VERTICAL,
      AggregationWidgetChartType.VERTICAL_GROUP,
      AggregationWidgetChartType.LINE,
      AggregationWidgetChartType.AREA,
      AggregationWidgetChartType.TABLE,
      AggregationWidgetChartType.HEAT_MAP,
    ],
  };

  public static readonly DEFAULT_DATE_RANGE = new TrendDateRange({
    trendSpan: new TrendSpan({ duration: 28, unit: ChronoUnit[ChronoUnit.DAYS] }),
    samplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.DAYS] }),
  });

  public static readonly appPlatformByApteligentPlatform = {
    IOS: AppPlatform.APPLE_IOS,
    ANDROID: AppPlatform.ANDROID,
  };
  public static readonly entityByTypeExtension = {
    IOS: '_ios',
    ANDROID: '_android',
  };

  public static readonly appErrorTypeByDetailTabType = {
    [AppDetailTabType.ERRORS]: AppErrorType.CRASH,
    [AppDetailTabType.HANDLED_EXCEPTION]: AppErrorType.HANDLED_EXCEPTION,
    [AppDetailTabType.PLUGIN_EXCEPTION]: AppErrorType.PLUGIN_EXCEPTION,
  };
  public static readonly appErrorOverrideSettingsByPlatformByErrorType = {
    [AppErrorType.CRASH]: {
      [AppPlatform.APPLE_IOS]: {
        entity: Entity.CRASH_IOS,
        groupedEntity: Entity.GROUPED_CRASH_IOS,
      },
      [AppPlatform.ANDROID]: {
        entity: Entity.CRASH_ANDROID,
        groupedEntity: Entity.GROUPED_CRASH_ANDROID,
      },
    },
    [AppErrorType.HANDLED_EXCEPTION]: {
      [AppPlatform.APPLE_IOS]: {
        entity: Entity.HANDLED_EXCEPTION_IOS,
        groupedEntity: Entity.HANDLED_EXCEPTION_IOS,
      },
      [AppPlatform.ANDROID]: {
        entity: Entity.HANDLED_EXCEPTION_ANDROID,
        groupedEntity: Entity.HANDLED_EXCEPTION_ANDROID,
      },
    },
    [AppErrorType.PLUGIN_EXCEPTION]: {
      [AppPlatform.APPLE_IOS]: {
        entity: Entity.PLUGIN_EXCEPTION,
        groupedEntity: Entity.PLUGIN_EXCEPTION,
      },
      [AppPlatform.ANDROID]: {
        entity: Entity.PLUGIN_EXCEPTION,
        groupedEntity: Entity.PLUGIN_EXCEPTION,
      },
    },
  };

  public static skipDefaultTrendDateDashboardTypes: StandardDashboardType[] = [
    StandardDashboardType.DEEM_VIRTUAL_SESSION_DETAILS_METADATA,
    StandardDashboardType.DEEM_VIRTUAL_SESSION_DETAILS_OVERVIEW,
  ];

  public static defaultTrendDateConfig: Array<{ duration: number; chronoUnit: ChronoUnit; dashboardTypes: StandardDashboardType[] }> = [
    {
      duration: 28,
      chronoUnit: ChronoUnit.DAYS,
      dashboardTypes: [StandardDashboardType.WORKSPACE_ONE_MTD],
    },
    {
      duration: 7,
      chronoUnit: ChronoUnit.DAYS,
      dashboardTypes: [
        StandardDashboardType.DEEM_PHYSICAL_DEVICES_WINDOWS,
        StandardDashboardType.DEEM_PHYSICAL_DEVICES_MACOS,
        StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APP,
        StandardDashboardType.DEEM_PHYSICAL_APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP,
        StandardDashboardType.DEEM_PHYSICAL_APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP_MACOS_ERROR_DETAILS,
        StandardDashboardType.DEEM_PHYSICAL_APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP_WINDOWS_ERROR_DETAILS,
        StandardDashboardType.DEEM_PHYSICAL_APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP,
        StandardDashboardType.DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS_ERROR_DETAILS,
        StandardDashboardType.DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS_ERROR_DETAILS,
        StandardDashboardType.DEEM_PHYSICAL_APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP,
        StandardDashboardType.DEEM_PHYSICAL_AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP,
        StandardDashboardType.DEEM_PHYSICAL_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP,
        StandardDashboardType.DEEM_PHYSICAL_APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP,
        StandardDashboardType.DEEM_PHYSICAL_MOBILE_APP,
        StandardDashboardType.DEEM_VIRTUAL_MANAGEMENT_COMPONENTS,
        StandardDashboardType.LOGIN_RISK,
        StandardDashboardType.UAG_TUNNEL,
        StandardDashboardType.DEVICES_NETWORK_DESKTOP,
        StandardDashboardType.DEVICES_NETWORK_DESKTOP_SIGNAL,
        StandardDashboardType.DEVICES_NETWORK_DESKTOP_SIGNAL_SSID,
        StandardDashboardType.DEVICES_PERFORMANCE_DESKTOP,
        StandardDashboardType.DEEM_PHYSICAL_APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP,
        StandardDashboardType.DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP,
        StandardDashboardType.CARBON_BLACK,
        StandardDashboardType.DEEM_VIRTUAL_ORG,
        StandardDashboardType.DEEM_VIRTUAL_APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_APP,
        StandardDashboardType.DEEM_VIRTUAL_APP_CRASH_COUNT_ERROR_DETAILS,
        StandardDashboardType.DEEM_VIRTUAL_APP_CRASH_COUNT_HORIZON_EXPERIENCESCORE_APP,
        StandardDashboardType.DEEM_VIRTUAL_APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP,
        StandardDashboardType.DEEM_VIRTUAL_APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_APP,
        StandardDashboardType.DEEM_VIRTUAL_MGT_COMPONENTS,
        StandardDashboardType.DEEM_VIRTUAL_CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
        StandardDashboardType.DEEM_VIRTUAL_CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
        StandardDashboardType.DEEM_VIRTUAL_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
        StandardDashboardType.DEEM_VIRTUAL_SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
        StandardDashboardType.DEEM_VIRTUAL_STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
        StandardDashboardType.DEEM_VIRTUAL_DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
        StandardDashboardType.DEEM_VIRTUAL_CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
        StandardDashboardType.DEEM_VIRTUAL_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
        StandardDashboardType.DEEM_VIRTUAL_STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
        StandardDashboardType.DEEM_VIRTUAL_DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
      ],
    },
    {
      duration: 24,
      chronoUnit: ChronoUnit.HOURS,
      dashboardTypes: [
        StandardDashboardType.DEEM_PHYSICAL_ORG,
        StandardDashboardType.HORIZON_UTILIZATION_VMWARE_SDDC,
        StandardDashboardType.HORIZON_UTILIZATION_VMWARE_SDDC_HISTORICAL,
        StandardDashboardType.HORIZON_UTILIZATION_AZURE,
        StandardDashboardType.HORIZON_UTILIZATION_AZURE_HISTORICAL,
        StandardDashboardType.DEEM_PHYSICAL_CRASH_COUNT_EXPERIENCESCORE_DEVICE,
        StandardDashboardType.DEEM_PHYSICAL_CPU_HEALTH_EXPERIENCESCORE_DEVICE,
        StandardDashboardType.DEEM_PHYSICAL_DEVICES,
        StandardDashboardType.DEEM_PHYSICAL_DEVICE_TEMPERATURE_EXPERIENCESCORE_DEVICE,
        StandardDashboardType.DEEM_PHYSICAL_MEMORY_HEALTH_EXPERIENCESCORE_DEVICE,
        StandardDashboardType.DEEM_PHYSICAL_GPU_HEALTH_EXPERIENCESCORE_DEVICE,
        StandardDashboardType.DEEM_PHYSICAL_BATTERY_HEALTH_EXPERIENCESCORE_DEVICE,
        StandardDashboardType.DEEM_PHYSICAL_AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE,
        StandardDashboardType.DEEM_PHYSICAL_AVERAGE_SHUTDOWN_TIME_MILLIS_EXPERIENCESCORE_DEVICE,
        StandardDashboardType.DEEM_PHYSICAL_USER,
        StandardDashboardType.DEEM_VIRTUAL_LOGON_DURATION_USER_DETAIL,
        StandardDashboardType.DEEM_VIRTUAL_LOGON_EXPERIENCE,
        // deem virtual v2
        StandardDashboardType.DEEM_VIRTUAL_NETWORK_PROTOCOL,
        StandardDashboardType.DEEM_VIRTUAL_VM_PERFORMANCE,
        StandardDashboardType.DEEM_VIRTUAL_LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
        StandardDashboardType.DEEM_VIRTUAL_WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
        StandardDashboardType.DEEM_VIRTUAL_DOT11_SIGNAL_QUALITY_HORIZON_EXPERIENCESCORE_SESSION,
        StandardDashboardType.DEEM_VIRTUAL_PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION,
        StandardDashboardType.DEEM_VIRTUAL_PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
        StandardDashboardType.DEEM_VIRTUAL_CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION,
        StandardDashboardType.DEEM_VIRTUAL_MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION,
        StandardDashboardType.DEEM_VIRTUAL_DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
        StandardDashboardType.DEEM_VIRTUAL_DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION,
        StandardDashboardType.DEEM_SYNTHETIC_URL_MONITORING_TEST_DETAILS,
        StandardDashboardType.DEEM_VIRTUAL_SESSION_DETAILS_OVERVIEW,
        StandardDashboardType.DEEM_VIRTUAL_SESSION_DETAILS_APP_PERFORMANCE,
        StandardDashboardType.DEEM_VIRTUAL_MGT_COMP_CONNECTION_SERVER_DETAIL,
        StandardDashboardType.DEEM_VIRTUAL_MGT_COMP_UAG_APPLIANCE_DETAIL,
      ],
    },
    {
      duration: 4,
      chronoUnit: ChronoUnit.HOURS,
      dashboardTypes: [
        StandardDashboardType.DEEM_PHYSICAL_USER_DETAIL,
        StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS,
        StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS_WINDOWS,
        StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS_MACOS,
        StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS,
        StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS_ANDROID,
        StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS_IOS,
        StandardDashboardType.DEEM_VIRTUAL_SESSIONS,
        StandardDashboardType.DEEM_VIRTUAL_APPS,
      ],
    },
  ];

  public static readonly iconClassByPlatform = {
    [AppPlatform.ANDROID]: 'android',
    [AppPlatform.APPLE_IOS]: 'apple',
    [AppPlatform.APPLE_MACOS]: 'macos',
    [AppPlatform.APPLE_TVOS]: 'tvos',
    [AppPlatform.CHROME_OS]: 'chromeos',
    [AppPlatform.DESKTOP]: 'desktop',
    [AppPlatform.QNX]: 'blackberry',
    [AppPlatform.WINDOWS_7]: 'windows7',
    [AppPlatform.WINDOWS_DESKTOP]: 'windows',
    [AppPlatform.WINDOWS_PHONE]: 'windowsphone',
    [AppPlatform.WINDOWS_RUGGED]: 'windowsrugged',
  };
  public static readonly crashThresholdsByPlatform = {
    [AppPlatform.ANDROID]: COLUMN_NAMES.byName.threshold_92_5,
    [AppPlatform.APPLE_IOS]: COLUMN_NAMES.byName.threshold_86_0,
  };
  public static readonly crashThresholdsFqnByPlatform = {
    [Entity.GROUPED_CRASH_ANDROID]: COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_android_threshold,
    [Entity.GROUPED_CRASH_IOS]: COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_threshold,
  };
  public static readonly CROSS_ENTITY_INTEGRATIONS = [Integration.HORIZON];
  public static readonly colors = DashboardColors;
  public static readonly COMPARE_COLORS = [
    this.colors.orange,
    this.colors.babyBlue,
    this.colors.purple,
    this.colors.yellow,
    this.colors.pink,
  ];
  public static readonly appErrorBreadcrumbColumnNames = [
    COLUMN_NAMES.byName._device_friendly_name,
    COLUMN_NAMES.byName._app_version,
    COLUMN_NAMES.byName.event_timestamp,
  ];
  public static readonly appErrorBreadcrumbAdditionalColumnNames = [
    COLUMN_NAMES.byName.device_uuid,
    COLUMN_NAMES.byName.app_id,
    COLUMN_NAMES.byName.platform,
    COLUMN_NAMES.byName.event_id,
  ];

  public static appCrashStacktraceDefaultColumnsForAndroid = [
    COLUMN_NAMES.byName._error_name,
    COLUMN_NAMES.byName.error_reason,
    COLUMN_NAMES.byName._app_version,
    COLUMN_NAMES.byName.event_timestamp,
    COLUMN_NAMES.byName.stacktrace_lines,
  ];

  public static appCrashStacktraceDefaultColumnsForIos = [
    COLUMN_NAMES.byName._error_name,
    COLUMN_NAMES.byName.error_reason,
    COLUMN_NAMES.byName.error_type,
    COLUMN_NAMES.byName.error_code,
    COLUMN_NAMES.byName.missing_symbolication_files,
    COLUMN_NAMES.byName.partially_symbolicated,
    COLUMN_NAMES.byName._app_version,
    COLUMN_NAMES.byName.event_timestamp,
    COLUMN_NAMES.byName.stacktrace_lines,
  ];

  public static handledExceptionsStacktraceDefaultColumnsForAndroid = [
    COLUMN_NAMES.byName._error_name,
    COLUMN_NAMES.byName.error_reason,
    COLUMN_NAMES.byName._app_version,
    COLUMN_NAMES.byName.event_timestamp,
    COLUMN_NAMES.byName.error_stacktrace_txt,
  ];

  public static appStacktraceDefaultColumns = [
    COLUMN_NAMES.byName._error_name,
    COLUMN_NAMES.byName.error_reason,
    COLUMN_NAMES.byName._app_version,
    COLUMN_NAMES.byName.event_timestamp,
    COLUMN_NAMES.byName.error_trace,
  ];

  public static readonly appDashboardColumnNames = {
    [Entity.GROUPED_CRASH_ANDROID]: DashboardConfig.appCrashStacktraceDefaultColumnsForAndroid,
    [Entity.GROUPED_CRASH_IOS]: DashboardConfig.appCrashStacktraceDefaultColumnsForIos,
    [Entity.HANDLED_EXCEPTION_ANDROID]: DashboardConfig.handledExceptionsStacktraceDefaultColumnsForAndroid,
    [Entity.HANDLED_EXCEPTION_IOS]: DashboardConfig.appStacktraceDefaultColumns,
    [Entity.PLUGIN_EXCEPTION]: DashboardConfig.appStacktraceDefaultColumns,
  };

  public static readonly uemDeviceUrlPath = '/AirWatch/#/AirWatch/Device/Details/Summary/';
  public static readonly EdiLineItemDelimitor = '|';
  public static readonly EdiLineItemSeparator = ' : ';

  public static readonly TOOLTIP_BG_COLOR = '#002538';
  public static readonly DEFAULT_COLORS = [
    '#004d8a',
    '#aadb1e',
    '#089de2',
    '#60b515',
    '#7ecdf1',
    '#dff0d0',
    '#29abe7',
    '#00968b',
    '#e0f4fc',
    '#006668',
    '#c1cdd4',
    '#00bfa9',
    '#027dc0',
    '#314351',
    '#026cac',
    '#85c81a',
    '#068fd4',
    '#c7e59c',
    '#4cb9ea',
    '#007e7a',
    '#b0e1f8',
    '#00ab9a',
    '#919fa8',
  ];
  public static readonly COLOR_SCHEMA_COLORS = [
    '#c92100',
    '#29abe7',
    '#4cb9ea',
    '#62a420',
    '#f38b00',
    '#00968b',
    '#007e7a',
    '#fac400',
    '#004a70',
    '#b0e1f8',
    '#ffdc0b',
    '#89cbdf',
    '#006668',
    '#00ab9a',
    '#9460b8',
    '#c1cdd4',
    '#919fa8',
    '#efc006',
    '#00bfa9',
    '#e0f4fc',
    '#efd603',
    '#004d8a',
    '#027dc0',
    '#f54f47',
    '#c7e59c',
    '#aadb1e',
    '#314351',
    '#00d4b8',
    '#dff0d0',
    '#089de2',
    '#026cac',
    '#85c81a',
    '#60b515',
    '#7ecdf1',
    '#068fd4',
    '#f38b00',
  ];
  public static readonly STATUS_COLORS = ['#E62700', '#62A420', '#F19036', '#F8CE3C', '#CCCCCC'];
  public static readonly OVERLAY_WIDGET_COLORS = ['#ff3d00', '#bf360c', '#ff8f00', '#ff6f00', '#ff5722', '#e65100', '#ffca28', '#ffab00'];
  public static readonly HEAT_MAP_COLORS = ['#e0f4fc', '#026cac'];
  public static readonly MAX_X_AXIS_TICK_LENGTH: number = 10;
  public static readonly MAX_X_AXIS_TICK_LENGTH_NO_GROUPS: number = 50;
  public static readonly MAX_VISIBLE_DONUT_LABELS: number = 20;
  public static readonly MIN_AUTO_LABEL_LEGEND_COLS: number = 3;
  public static readonly MIN_AUTO_LABEL_LEGEND_ROWS: number = 1;
  public static readonly MIN_CVE_SCORE: number = 7;
  public static readonly MIN_PERCENTAGE_IMPACTED_DEVICE: number = 0.1;
  public static readonly DEFAULT_CVS_SEARCH_REQUEST_SIZE_LIMIT = 100;
  public static readonly successFailureColors = [
    {
      name: 'Failure',
      value: '#f54f47',
    },
    {
      name: 'Success',
      value: '#00d4b8',
    },
  ];
  public static readonly highMediumLowColors = [
    {
      name: 'High',
      value: '#f54f47',
    },
    {
      name: 'Medium',
      value: '#fac400',
    },
    {
      name: 'Low',
      value: '#62a420',
    },
  ];
  public static readonly poorNeutralGoodColors = [
    {
      name: 'Good',
      value: '#62a420',
    },
    {
      name: 'Neutral',
      value: '#fac400',
    },
    {
      name: 'Poor',
      value: '#f54f47',
    },
  ];
  public static readonly badPoorFairGoodColors = [
    {
      name: 'good',
      value: '#209C52',
    },
    {
      name: 'fair',
      value: '#FAC400',
    },
    {
      name: 'poor',
      value: '#A36500',
    },
    {
      name: 'bad',
      value: '#C92100',
    },
  ];
  public static readonly unknownColorSet = {
    name: 'UNKNOWN_VALUE',
    color: 'gray',
  };
  public static readonly workflowRunHistoryColors = [
    ...AUTOMATION_SUCCESS_STATUS.map((name: string) => ({
      name,
      value: RatioIndicatorColors.SUCCESS,
    })),
    ...AUTOMATION_ERROR_STATUS.map((name: string) => ({
      name,
      value: RatioIndicatorColors.ERROR,
    })),
    ...AUTOMATION_PENDING_STATUS.map((name: string) => ({
      name,
      value: RatioIndicatorColors.ACTIVE,
    })),
  ];

  public static readonly thresholdColors = ['#ffdc0b', '#c92100'];
  public static readonly selectedPeriodColor = '#004d8a';
  public static readonly previousPeriodColor = '#00d4b8';
  public static readonly DEFAULT_BAR_COLOR = '#0079B8';
  public static readonly DEFAULT_MARKER_COLOR = '#000000';
  public static readonly CVSS_LABEL_THRESHOLDS: CvssLabelThreshold[] = [
    {
      labelKey: 'COMMON_MESSAGES.CRITICAL',
      baseThreshold: 9,
      badgeClass: 'badge-danger',
    },
    {
      labelKey: 'COMMON_MESSAGES.HIGH',
      baseThreshold: 7,
      badgeClass: 'badge-orange',
    },
    {
      labelKey: 'COMMON_MESSAGES.MEDIUM',
      baseThreshold: 4,
      badgeClass: 'badge-warning',
    },
    {
      labelKey: 'COMMON_MESSAGES.LOW',
      baseThreshold: 0.1,
      badgeClass: 'badge-purple',
    },
    {
      labelKey: 'COMMON_MESSAGES.NONE',
      baseThreshold: 0,
      badgeClass: 'badge',
    },
  ];
  public static readonly LOOK_UP_ATTRIBUTE_FOR_WIDGET_SKIN = {
    name: COLUMN_NAMES.byName.name,
    cveIdList: COLUMN_NAMES.byName.cve_id_list,
    appId: COLUMN_NAMES.byName.app_id,
  };
  public static readonly POTENTIAL_IMPACT_DASHBOARD_TYPE_BY_ENTITY = {
    [Entity.EXPERIENCESCORE_DEVICE]: 'DEEM_INCIDENT_DEVICE',
    [Entity.EXPERIENCESCORE_DESKTOP_APP]: 'DEEM_INCIDENT_DESKTOP_APP',
    [Entity.EXPERIENCESCORE_MOBILE_APP]: 'DEEM_INCIDENT_MOBILE_APP',
    [Entity.EXPERIENCESCORE_USER]: 'DEEM_INCIDENT_USER',
  };

  // These are used during trend composition
  public static timePeriodBucketName = '_time_period';
  public static timePeriodBucketLabel = 'Time period';
  public static selectedTimePeriodName = 'Selected period';
  public static previousTimePeriodName = 'Previous period';

  public static readonly subtypeLabelKeysBySubtype = {
    DEEM_APP_BOOTS: 'WIDGET_TABLE_SUBTYPE_LABELS.DEEM_APP_BOOTS',
    DEEM_APP_ERRORS: 'WIDGET_TABLE_SUBTYPE_LABELS.DEEM_APP_ERRORS',
    DEEM_APP_HANG: 'WIDGET_TABLE_SUBTYPE_LABELS.DEEM_APP_HANG',
    DEVICES_BATTERY_GOOD_TREND: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_BATTERY_GOOD',
    DEVICES_BATTERY_MEDIUM_TREND: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_BATTERY_MEDIUM',
    DEVICES_BATTERY_POOR_TREND: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_BATTERY_POOR',
    DEVICES_CAPACITY_CRITICAL_TREND: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_CAPACITY_CRITICAL',
    DEVICES_CAPACITY_WARNING_TREND: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_CAPACITY_WARNING',
    DEVICES_ENROLLMENT_ACTIVE_TREND: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_ENROLLMENT_ACTIVE_HISTORICAL',
    DEVICES_ENROLLMENT_INACTIVE_TREND: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_ENROLLMENT_INACTIVE_HISTORICAL',
    DEVICES_ACTIVE_OVER_TIME: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_ACTIVE_OVER_TIME',
    DEVICES_INACTIVE_OVER_TIME: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_INACTIVE_OVER_TIME',
    DEVICES_TOTAL_ACTIVE: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_TOTAL_ACTIVE',
    DEVICES_TOTAL_INACTIVE: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_TOTAL_INACTIVE',
    DEVICES_TOTAL_ACTIVE_COUNT_TREND: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_ENROLLMENT_ACTIVE_HISTORICAL',
    DEVICES_TOTAL_INACTIVE_COUNT_TREND: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_ENROLLMENT_INACTIVE_HISTORICAL',
    DEVICES_PERFORMANCE_BOOT_TIME_WARNING: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_PERFORMANCE_BOOT_TIME_WARNING',
    DEVICES_PERFORMANCE_BOOT_TIME_CRITICAL: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_PERFORMANCE_BOOT_TIME_CRITICAL',
    DEVICES_PERFORMANCE_LOGIN_TIME_WARNING: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_PERFORMANCE_LOGIN_TIME_WARNING',
    DEVICES_PERFORMANCE_LOGIN_TIME_CRITICAL: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_PERFORMANCE_LOGIN_TIME_CRITICAL',
    DEVICES_PERFORMANCE_SHUTDOWN_TIME_WARNING: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_PERFORMANCE_SHUTDOWN_TIME_WARNING',
    DEVICES_PERFORMANCE_SHUTDOWN_TIME_CRITICAL: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_PERFORMANCE_SHUTDOWN_TIME_CRITICAL',
    APTELIGENT_APP_ROLLING_MONTHLY_ACTIVE_USERS: 'WIDGET_TABLE_SUBTYPE_LABELS.APTELIGENT_APP_ROLLING_MONTHLY_ACTIVE_USERS',
    APTELIGENT_APP_ROLLING_DAILY_ACTIVE_USERS: 'WIDGET_TABLE_SUBTYPE_LABELS.APTELIGENT_APP_ROLLING_DAILY_ACTIVE_USERS',
    USER_FLOWS_FAILURE_RATE: 'WIDGET_TABLE_SUBTYPE_LABELS.USER_FLOWS_FAILURE_RATE',
    USER_FLOWS_ALL_OCCURRENCES_HISTOGRAM: 'WIDGET_TABLE_SUBTYPE_LABELS.USER_FLOWS_ALL_OCCURRENCES_HISTOGRAM',
    HORIZON_SESSION_ISSUES_BY_LATENCY_TYPE: 'WIDGET_TABLE_SUBTYPE_LABELS.HORIZON_SESSION_ISSUES_BY_LATENCY_TYPE',
    HORIZON_SESSION_ISSUES_BY_LOGON_TYPE: 'WIDGET_TABLE_SUBTYPE_LABELS.HORIZON_SESSION_ISSUES_BY_LOGON_TYPE',
    HORIZON_SESSION_ISSUES_BY_PROTOCOL_TYPE: 'WIDGET_TABLE_SUBTYPE_LABELS.HORIZON_SESSION_ISSUES_BY_PROTOCOL_TYPE',
    NETWORK_INSIGHTS_NET_EVENT_ERROR: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_ERROR',
    NETWORK_INSIGHTS_NET_ERRORS: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_ERRORS',
    NETWORK_INSIGHTS_NET_EVENT: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT',
    NETWORK_INSIGHTS_NET_EVENT_ERROR_PREVIOUS_PERIOD: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_ERROR_PREVIOUS_PERIOD',
    NETWORK_INSIGHTS_NET_ERRORS_PREVIOUS_PERIOD: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_ERRORS_PREVIOUS_PERIOD',
    NETWORK_INSIGHTS_NET_EVENT_PREVIOUS_PERIOD: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_PREVIOUS_PERIOD',
    NETWORK_INSIGHTS_AVG_LATENCY: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_AVG_LATENCY',
    NETWORK_INSIGHTS_AVG_LATENCY_PREVIOUS_PERIOD: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_AVG_LATENCY_PREVIOUS_PERIOD',
    NETWORK_INSIGHTS_BYTES_RECEIVED: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_BYTES_RECEIVED',
    NETWORK_INSIGHTS_BYTES_RECEIVED_PREVIOUS_PERIOD: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_BYTES_RECEIVED_PREVIOUS_PERIOD',
    NETWORK_INSIGHTS_BYTES_SENT: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_BYTES_SENT',
    NETWORK_INSIGHTS_BYTES_SENT_PREVIOUS_PERIOD: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_BYTES_SENT_PREVIOUS_PERIOD',
    NETWORK_INSIGHTS_NET_ERRORS_BY_APP_VERSION: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_ERRORS_BY_APP_VERSION',
    NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_APP_VERSION: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_APP_VERSION',
    NETWORK_INSIGHTS_NET_EVENT_BY_APP_VERSION: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_BY_APP_VERSION',
    NETWORK_INSIGHTS_NET_ERRORS_BY_SYSTEM_VERSION: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_ERRORS_BY_SYSTEM_VERSION',
    NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_SYSTEM_VERSION: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_SYSTEM_VERSION',
    NETWORK_INSIGHTS_NET_EVENT_BY_SYSTEM_VERSION: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_BY_SYSTEM_VERSION',
    NETWORK_INSIGHTS_NET_ERRORS_BY_CARRIER: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_ERRORS_BY_CARRIER',
    NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_CARRIER: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_CARRIER',
    NETWORK_INSIGHTS_NET_EVENT_BY_CARRIER: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_BY_CARRIER',
    NETWORK_INSIGHTS_NET_ERRORS_BY_DEVICE_MODEL: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_ERRORS_BY_DEVICE_MODEL',
    NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_DEVICE_MODEL: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_DEVICE_MODEL',
    NETWORK_INSIGHTS_NET_EVENT_BY_DEVICE_MODEL: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_BY_DEVICE_MODEL',
    NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE: 'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE',
    NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE_BY_CODE:
      'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE_BY_CODE',
    NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_STATUS_MESSAGE_BY_CODE:
      'WIDGET_TABLE_SUBTYPE_LABELS.NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_STATUS_MESSAGE_BY_CODE',
    DEVICES_WITH_GIVEN_CVE_BY_KB_NUMBER: 'WIDGET_TABLE_SUBTYPE_LABELS.DEVICES_WITH_GIVEN_CVE_BY_KB_NUMBER',
    NON_VULNERABLE_DEVICES_WITH_GIVEN_CVE_BY_KB_NUMBER: 'WIDGET_TABLE_SUBTYPE_LABELS.NON_VULNERABLE_DEVICES_WITH_GIVEN_CVE_BY_KB_NUMBER',
  };
  public static readonly networkInsightsChartTitleKeysByAllHostTab = {
    HTTP_ERRORS: 'COMMON_MESSAGES.ERRORS',
    NUMBER_OF_CALLS: 'APTELIGENT.NUMBER_OF_CALLS',
    AVG_LATENCY: 'APTELIGENT.AVG_RESPONSE_TIME',
    ERROR_RATE: 'APTELIGENT.ERROR_RATE',
    BYTES_RECEIVED: 'APTELIGENT.AVG_DATA_IN',
    BYTES_SENT: 'APTELIGENT.AVG_DATA_OUT',
  };
  // For performance reasons, these trends will be requested in a separate call
  public static readonly WIDGET_SUBTYPES_TO_LOAD_SEPARATELY = new Set([
    'APTELIGENT_APP_ROLLING_DAILY_ACTIVE_USERS',
    'APTELIGENT_APP_ROLLING_MONTHLY_ACTIVE_USERS',
    'APP_SUMMARY_MAU_TOP_10_WINDOWS_APPS',
    'APP_ERRORS_BY_EVENT_NAME',
    'APP_ERRORS_HANG_EVENTS_COUNT',
    'APP_ERRORS_HANG_EVENTS_COUNT',
    'APP_ERRORS_HANG_EVENTS_COUNT_PREVIOUS_PERIOD',
    'APP_ERRORS_CRASH_EVENTS_COUNT',
    'APP_ERRORS_CRASH_EVENTS_COUNT',
    'APP_ERRORS_CRASH_EVENTS_COUNT_PREVIOUS_PERIOD',
    'HORIZON_DESKTOP_SESSION_UTILIZATION_FOR_AZURE_HISTORICAL',
    'HORIZON_APPLICATION_SESSION_UTILIZATION_FOR_AZURE_HISTORICAL',
    'HORIZON_CAPACITY_USAGE_FOR_AZURE_HISTORICAL',
  ]);
  public static readonly COMPOSE_CONFIGS_BY_STANDARD_DASHBOARD = {
    APP_EXPERIENCE: {
      __CHANGE_IN_APP_CRASH_RATE: {
        dependencies: ['APP_EXPERIENCE_APP_CRASH_RATE', 'APP_EXPERIENCE_APP_CRASH_RATE_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.COUNT_CHANGE,
      },
      __CHANGE_IN_APP_LOAD_RATE: {
        dependencies: ['APP_EXPERIENCE_APP_LOADS', 'APP_EXPERIENCE_APP_LOADS_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.COUNT_CHANGE,
      },
      __CHANGE_IN_APP_NETWORK_ERROR_RATE: {
        dependencies: ['APP_EXPERIENCE_APP_NETWORK_ERROR_RATE', 'APP_EXPERIENCE_APP_NETWORK_ERROR_RATE_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.COUNT_CHANGE,
      },
      __CHANGE_IN_APP_HANDLED_EXCEPTION_RATE: {
        dependencies: ['APP_EXPERIENCE_APP_HANDLED_EXCEPTION_RATE', 'APP_EXPERIENCE_APP_HANDLED_EXCEPTION_RATE_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.COUNT_CHANGE,
      },
      __CHANGE_IN_APP_DAU_RATE: {
        dependencies: ['APP_EXPERIENCE_APP_DAU', 'APP_EXPERIENCE_APP_DAU_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.COUNT_CHANGE,
      },
    },
    APP_DEPLOYMENT: {
      __APP_DEPLOYMENT_UPDATES_RATE: {
        dependencies: ['APP_DEPLOYMENT_UPDATES_TOTAL_COUNT', 'APP_DEPLOYMENT_MANAGED_APPS_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __APP_DEPLOYMENT_UPDATES_RATE_PREVIOUS_PERIOD: {
        dependencies: ['APP_DEPLOYMENT_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD', 'APP_DEPLOYMENT_MANAGED_APPS_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __APP_DEPLOYMENT_FAILED_UPDATES_RATE: {
        dependencies: ['APP_DEPLOYMENT_FAILED_UPDATES_TOTAL_COUNT', 'APP_DEPLOYMENT_MANAGED_APPS_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __APP_DEPLOYMENT_FAILED_UPDATES_RATE_PREVIOUS_PERIOD: {
        dependencies: ['APP_DEPLOYMENT_FAILED_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD', 'APP_DEPLOYMENT_MANAGED_APPS_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
    },
    APP_ERRORS: {
      __APP_ERRORS_CRASH_RATE: {
        dependencies: ['APP_ERRORS_CRASHES_COUNT', 'APP_ERRORS_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __APP_ERRORS_CRASH_RATE_PREVIOUS_PERIOD: {
        dependencies: ['APP_ERRORS_CRASHES_COUNT_PREVIOUS_PERIOD', 'APP_ERRORS_TOTAL_COUNT_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __APP_ERRORS_CRASH_EVENTS_RATIO_CHANGE: {
        dependencies: ['__APP_ERRORS_CRASH_EVENTS_RATIO', '__APP_ERRORS_CRASH_EVENTS_RATIO_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __APP_ERRORS_CRASH_EVENTS_RATIO: {
        dependencies: ['APP_ERRORS_CRASH_EVENTS_COUNT', 'APP_ERRORS_COMPLETE_EVENTS_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __APP_ERRORS_CRASH_EVENTS_RATIO_PREVIOUS_PERIOD: {
        dependencies: ['APP_ERRORS_CRASH_EVENTS_COUNT_PREVIOUS_PERIOD', 'APP_ERRORS_COMPLETE_EVENTS_COUNT_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __APP_ERROR_OCCURENCES_BY_MEMORY_USAGE_SNAPSHOT: {
        dependencies: ['APP_ERROR_OCCURENCES_BY_MEMORY_USAGE'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __APP_ERROR_OCCURENCES_BY_DISK_SPACE_FREE_SNAPSHOT: {
        dependencies: ['APP_ERROR_OCCURENCES_BY_DISK_SPACE_FREE'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __APP_ERROR_OCCURENCES_BY_DISK_SPACE_TOTAL_SNAPSHOT: {
        dependencies: ['APP_ERROR_OCCURENCES_BY_DISK_SPACE_TOTAL'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __DEEM_APP_CRASH_RATE_HISTORICAL: {
        dependencies: ['DEEM_APP_ERRORS', 'DEEM_APP_BOOTS'],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
    },
    CVE: {
      __VULNERABLE_DEVICES_COUNT_BY_KB: {
        dependencies: ['DEVICES_WITH_GIVEN_CVE_BY_KB_NUMBER', 'NON_VULNERABLE_DEVICES_WITH_GIVEN_CVE_BY_KB_NUMBER'],
        composeFunction: ComposeFunction.DIFF_BY_BUCKETS,
      },
    },
    INSIGHTS: {
      // APP_CRASHES, APP_HANGS and APP_USAGE
      [StandardWidgetSubtype.__INSIGHT_IMPACT_APPS]: {
        dependencies: [StandardWidgetSubtype.INSIGHT_IMPACT_APPS_BY_VERSION, StandardWidgetSubtype.INSIGHT_IMPACT_APPS_BY_VERSION_TOTAL],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      // BOOT_DURATION, SHUTDOWN_DURATION
      [StandardWidgetSubtype.__INSIGHT_IMPACT_DURATION]: {
        dependencies: [
          StandardWidgetSubtype.INSIGHT_IMPACT_DURATION_BY_OS_VERSION,
          StandardWidgetSubtype.INSIGHT_IMPACT_DURATION_BY_OS_VERSION_TOTAL,
        ],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      [StandardWidgetSubtype.__INSIGHT_IMPACT_OS_CRASHES]: {
        dependencies: [
          StandardWidgetSubtype.INSIGHT_IMPACT_OS_CRASHES_BY_OS_VERSION,
          StandardWidgetSubtype.INSIGHT_IMPACT_OS_CRASHES_BY_OS_VERSION_TOTAL,
        ],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      [StandardWidgetSubtype.__INSIGHT_IMPACT_CPU]: {
        dependencies: [
          StandardWidgetSubtype.INSIGHT_IMPACT_CPU_BY_OS_VERSION,
          StandardWidgetSubtype.INSIGHT_IMPACT_CPU_BY_OS_VERSION_TOTAL,
        ],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      [StandardWidgetSubtype.__INSIGHT_IMPACT_HORIZON_SESSION]: {
        dependencies: [
          StandardWidgetSubtype.INSIGHT_IMPACT_HORIZON_SESSION_BY_POOL_ID,
          StandardWidgetSubtype.INSIGHT_IMPACT_HORIZON_SESSION_TOTAL,
        ],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
    },
    NETWORK_INSIGHTS: {
      __NETWORK_INSIGHTS_ERROR_RATE: {
        dependencies: ['__NETWORK_INSIGHTS_TOTAL_ERROR_COUNT', '__NETWORK_INSIGHTS_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __NETWORK_INSIGHTS_TOTAL_ERROR_COUNT: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_COUNT', 'NETWORK_INSIGHTS_NET_EVENT_ERROR_COUNT'],
        composeFunction: ComposeFunction.SUM_ALL_COUNT,
      },
      __NETWORK_INSIGHTS_TOTAL_COUNT: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_COUNT', 'NETWORK_INSIGHTS_NET_EVENT_COUNT'],
        composeFunction: ComposeFunction.SUM_ALL_COUNT,
      },
      __NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL: {
        dependencies: ['__NETWORK_INSIGHTS_TOTAL_ERROR', '__NETWORK_INSIGHTS_TOTAL'],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_ERROR: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS', 'NETWORK_INSIGHTS_NET_EVENT_ERROR'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS', 'NETWORK_INSIGHTS_NET_EVENT'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL_PREVIOUS_PERIOD: {
        dependencies: ['__NETWORK_INSIGHTS_TOTAL_ERROR_PREVIOUS_PERIOD', '__NETWORK_INSIGHTS_TOTAL_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_ERROR_PREVIOUS_PERIOD: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_PREVIOUS_PERIOD', 'NETWORK_INSIGHTS_NET_EVENT_ERROR_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_PREVIOUS_PERIOD: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_PREVIOUS_PERIOD', 'NETWORK_INSIGHTS_NET_EVENT_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },

      __NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL_BOTH_PERIODS: {
        dependencies: ['__NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL', '__NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.MERGE_PREVIOUS_PERIOD_SERIES,
      },
      __NETWORK_INSIGHTS_TOTAL_ERROR_BOTH_PERIODS: {
        dependencies: ['__NETWORK_INSIGHTS_TOTAL_ERROR', '__NETWORK_INSIGHTS_TOTAL_ERROR_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.MERGE_PREVIOUS_PERIOD_SERIES,
      },
      __NETWORK_INSIGHTS_TOTAL_BOTH_PERIODS: {
        dependencies: ['__NETWORK_INSIGHTS_TOTAL', '__NETWORK_INSIGHTS_TOTAL_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.MERGE_PREVIOUS_PERIOD_SERIES,
      },

      __NETWORK_INSIGHTS_AVG_LATENCY_BOTH_PERIODS: {
        dependencies: ['NETWORK_INSIGHTS_AVG_LATENCY', 'NETWORK_INSIGHTS_AVG_LATENCY_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.MERGE_PREVIOUS_PERIOD_SERIES,
      },
      __NETWORK_INSIGHTS_BYTES_RECEIVED_BOTH_PERIODS: {
        dependencies: ['NETWORK_INSIGHTS_BYTES_RECEIVED', 'NETWORK_INSIGHTS_BYTES_RECEIVED_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.MERGE_PREVIOUS_PERIOD_SERIES,
      },
      __NETWORK_INSIGHTS_BYTES_SENT_BOTH_PERIODS: {
        dependencies: ['NETWORK_INSIGHTS_BYTES_SENT', 'NETWORK_INSIGHTS_BYTES_SENT_PREVIOUS_PERIOD'],
        composeFunction: ComposeFunction.MERGE_PREVIOUS_PERIOD_SERIES,
      },
      __NETWORK_INSIGHTS_TOTAL_ERROR_BY_APP_VERSION: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_BY_APP_VERSION', 'NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_APP_VERSION'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_COUNT_BY_APP_VERSION: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_BY_APP_VERSION', 'NETWORK_INSIGHTS_NET_EVENT_BY_APP_VERSION'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_ERROR_RATE_BY_APP_VERSION: {
        dependencies: ['__NETWORK_INSIGHTS_TOTAL_ERROR_BY_APP_VERSION', '__NETWORK_INSIGHTS_TOTAL_COUNT_BY_APP_VERSION'],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_ERROR_BY_SYSTEM_VERSION: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_BY_SYSTEM_VERSION', 'NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_SYSTEM_VERSION'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_COUNT_BY_SYSTEM_VERSION: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_BY_SYSTEM_VERSION', 'NETWORK_INSIGHTS_NET_EVENT_BY_SYSTEM_VERSION'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_ERROR_RATE_BY_SYSTEM_VERSION: {
        dependencies: ['__NETWORK_INSIGHTS_TOTAL_ERROR_BY_SYSTEM_VERSION', '__NETWORK_INSIGHTS_TOTAL_COUNT_BY_SYSTEM_VERSION'],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_ERROR_BY_CARRIER: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_BY_CARRIER', 'NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_CARRIER'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_COUNT_BY_CARRIER: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_BY_CARRIER', 'NETWORK_INSIGHTS_NET_EVENT_BY_CARRIER'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_ERROR_RATE_BY_CARRIER: {
        dependencies: ['__NETWORK_INSIGHTS_TOTAL_ERROR_BY_CARRIER', '__NETWORK_INSIGHTS_TOTAL_COUNT_BY_CARRIER'],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_ERROR_BY_DEVICE_MODEL: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_BY_DEVICE_MODEL', 'NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_DEVICE_MODEL'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_COUNT_BY_DEVICE_MODEL: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_BY_DEVICE_MODEL', 'NETWORK_INSIGHTS_NET_EVENT_BY_DEVICE_MODEL'],
        composeFunction: ComposeFunction.SUM_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_ERROR_RATE_BY_DEVICE_MODEL: {
        dependencies: ['__NETWORK_INSIGHTS_TOTAL_ERROR_BY_DEVICE_MODEL', '__NETWORK_INSIGHTS_TOTAL_COUNT_BY_DEVICE_MODEL'],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
      __NETWORK_INSIGHTS_TOTAL_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT_ANDROID: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE', '__NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_STATUS_MESSAGE_BY_CODE_FLAT'],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __NETWORK_INSIGHTS_TOTAL_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT_IOS: {
        dependencies: [
          '__NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT',
          '__NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_STATUS_MESSAGE_BY_CODE_FLAT',
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT: {
        dependencies: ['NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE_BY_CODE'],
        composeFunction: ComposeFunction.FLATTEN_BUCKETS,
      },
      __NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_STATUS_MESSAGE_BY_CODE_FLAT: {
        dependencies: ['NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_STATUS_MESSAGE_BY_CODE'],
        composeFunction: ComposeFunction.FLATTEN_BUCKETS,
      },
      __DEVICES_OVERVIEW_CAPACITY_HEALTH_TREND: {
        dependencies: ['DEVICES_CAPACITY_CRITICAL_TREND', 'DEVICES_CAPACITY_WARNING_TREND'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Capacity Health',
          seriesName: '_merged_series',
          seriesValues: ['Critical', 'Warning'],
        },
      },
      __DEVICES_ENROLLMENT_TREND: {
        dependencies: ['DEVICES_ENROLLMENT_ACTIVE_TREND', 'DEVICES_ENROLLMENT_INACTIVE_TREND'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Enrollment Status',
          seriesName: '_merged_series',
          seriesValues: ['Active', 'Inactive'],
        },
      },
      __DEVICES_TOTAL_ACTIVE_INACTIVE_COUNT_TREND: {
        dependencies: ['DEVICES_TOTAL_ACTIVE_COUNT_TREND', 'DEVICES_TOTAL_INACTIVE_COUNT_TREND'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Active Status',
          seriesName: '_merged_series',
          seriesValues: ['Active', 'Inactive'],
        },
      },
      __USER_FLOWS_FAILURE_RATE_HISTORICAL: {
        dependencies: ['USER_FLOWS_FAILURE_RATE', 'USER_FLOWS_ALL_OCCURRENCES_HISTOGRAM'],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
      __APTELIGENT_APP_ROLLING_DAU_OVER_MAU: {
        dependencies: ['APTELIGENT_APP_ROLLING_DAILY_ACTIVE_USERS', 'APTELIGENT_APP_ROLLING_MONTHLY_ACTIVE_USERS'],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
      _USER_PROFILE_NETWORK_FAILURE: {
        dependencies: ['USER_PROFILE_NETWORK_FAILURE'],
        composeFunction: ComposeFunction.FLATTEN_BUCKETS,
      },
      _USER_PROFILE_SUCCESS_USER_FLOWS: {
        dependencies: ['USER_PROFILE_SUCCESS_USER_FLOWS'],
        composeFunction: ComposeFunction.FLATTEN_BUCKETS,
      },
      _USER_PROFILE_FAILURE_USER_FLOWS: {
        dependencies: ['USER_PROFILE_FAILURE_USER_FLOWS'],
        composeFunction: ComposeFunction.FLATTEN_BUCKETS,
      },
    } as ComposeConfigIndex,
    OS_VERSIONS: {
      __OS_VERSIONS_UPDATES_RATE: {
        dependencies: ['OS_VERSIONS_UPDATES_TOTAL_COUNT', 'OS_VERSIONS_DEVICE_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __OS_VERSIONS_UPDATES_RATE_PREVIOUS_PERIOD: {
        dependencies: ['OS_VERSIONS_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD', 'OS_VERSIONS_DEVICE_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
    },
    OS_WINDOWS_PATCHES: {
      __OS_WINDOWS_PATCH_STATUS_UPDATES_RATE: {
        dependencies: ['OS_WINDOWS_PATCH_STATUS_UPDATES_TOTAL_COUNT', 'OS_WINDOWS_PATCH_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __OS_VERSIONS_UPDATES_RATE_PREVIOUS_PERIOD: {
        dependencies: ['OS_WINDOWS_PATCH_STATUS_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD', 'OS_WINDOWS_PATCH_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
    },
    SECURITY: {
      __SECURITY_COMPROMISED_DEVICES_RATE: {
        dependencies: ['SECURITY_COMPROMISED_DEVICES_UPDATES_TOTAL_COUNT', 'SECURITY_ENROLLED_DEVICES_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __SECURITY_COMPROMISED_DEVICES_RATE_PREVIOUS_PERIOD: {
        dependencies: ['SECURITY_COMPROMISED_DEVICES_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD', 'SECURITY_ENROLLED_DEVICES_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __SECURITY_ENCRYPTION_DEVICES_RATE: {
        dependencies: ['SECURITY_ENCRYPTION_STATUS_UPDATES_TOTAL_COUNT', 'SECURITY_ENROLLED_DEVICES_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __SECURITY_ENCRYPTION_DEVICES_RATE_PREVIOUS_PERIOD: {
        dependencies: ['SECURITY_ENCRYPTION_STATUS_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD', 'SECURITY_ENROLLED_DEVICES_TOTAL_COUNT'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __SECURITY_VULNERABILITIES_SUMMARY: {
        dependencies: ['SECURITY_VULNERABILITIES_SUMMARY', 'MACOS_SECURITY_VULNERABILITIES_SUMMARY'],
        composeFunction: ComposeFunction.SUM_ALL_COUNT,
      },
    },
    ...DeemSolutionDashboardConfig.COMPOSE_CONFIGS_BY_DEEM_DASHBOARD,
    ...HorizonDashboardConfig.COMPOSE_CONFIGS_BY_HORIZON_DASHBOARD,
    ...UserProfileDashboardConfig.COMPOSE_CONFIGS_BY_USER_PROFILE_DASHBOARD,
    ...DevicesDashboardConfig.COMPOSE_CONFIGS_BY_DEVICES_DASHBOARD,
  };
  public static readonly preferredWidgetTabOrderByCategoryId = {
    [getUniqueId(Integration.APTELIGENT, Entity.NET_EVENT)]: 0,
    [getUniqueId(Integration.APTELIGENT, Entity.NET_ERROR)]: 1,
  };

  public static counterLabelOverridesByFuncByAttr = {
    [COLUMN_NAMES.byName.score_calculation_timestamp]: {
      [AggregationFunction.MAX]: 'STANDARD_DASHBOARD.COUNTER_COLUMN_OVERRIDES.ASSESSMENT_DATE',
    },
    [COLUMN_NAMES.byName.app_hang_count]: {
      [AggregationFunction.AVG]: 'STANDARD_DASHBOARD.COUNTER_COLUMN_OVERRIDES.AVG_APP_HANGS',
    },
    [COLUMN_NAMES.byName.app_crash_count]: {
      [AggregationFunction.AVG]: 'STANDARD_DASHBOARD.COUNTER_COLUMN_OVERRIDES.AVG_CRASH_COUNT',
    },
    [COLUMN_NAMES.byName.app_handled_exceptions]: {
      [AggregationFunction.AVG]: 'STANDARD_DASHBOARD.COUNTER_COLUMN_OVERRIDES.AVG_HANDLED_EXCEPTIONS',
    },
    [COLUMN_NAMES.byName.app_net_error_rate]: {
      [AggregationFunction.AVG]: 'STANDARD_DASHBOARD.COUNTER_COLUMN_OVERRIDES.AVG_NETWORK_ERROR_RATE',
    },
    [COLUMN_NAMES.byName.avg_app_latency_millis]: {
      [AggregationFunction.AVG]: 'STANDARD_DASHBOARD.COUNTER_COLUMN_OVERRIDES.AVG_NETWORK_LATENCY',
    },
    [COLUMN_NAMES.byName.impact]: {
      [AggregationFunction.AVG]: 'STANDARD_DASHBOARD.COUNTER_COLUMN_OVERRIDES.IMPACT',
    },
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_gpo_load_duration]: {
      [AggregationFunction.AVG]: 'STANDARD_DASHBOARD.COUNTER_COLUMN_OVERRIDES.LOGON_DURATION_IN_SECONDS',
    },
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_logon_duration]: {
      [AggregationFunction.AVG]: 'STANDARD_DASHBOARD.COUNTER_COLUMN_OVERRIDES.AVG_LOGON_DURATION_IN_SECONDS',
    },
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_session_uuid]: {
      [AggregationFunction.COUNT]: 'DEEM_VIRTUAL_SUMMARY.SESSION_COUNT',
    },
    [COLUMN_NAMES.byName.horizon_utilization_ratio]: {
      [AggregationFunction.COUNT]: 'COMMON_MESSAGES.VALUE',
    },
  };

  public static readonly entityDefaultSortBy: Record<string, Record<string, SortOn[]>> = {
    [getUniqueId(Integration.APTELIGENT, Entity.USER_FLOW)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.apteligent_user_flow_end_time,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.end_time,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.CARBONBLACK, Entity.THREAT)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.carbonblack_threat_event_timestamp,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.event_timestamp,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.CHECKPOINT, Entity.THREAT)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.checkpoint_threat_event_timestamp,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.event_timestamp,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.CROWDSTRIKE, Entity.THREAT)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.crowdstrike_threat_event_timestamp,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.event_timestamp,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.HORIZON, Entity.SESSION_SNAPSHOT)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.horizon_session_login_time,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.horizon_session_login_time,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.HORIZON, Entity.SESSION_SNAPSHOT_TITAN)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_login_time,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.HORIZON, Entity.VM)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.horizon_vm_desktop_name,
          reverse: false,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.horizon_vm_desktop_name,
          reverse: false,
        }),
      ],
    },
    [getUniqueId(Integration.HORIZON, Entity.VM_TITAN)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_desktop_name,
          reverse: false,
        }),
      ],
    },
    [getUniqueId(Integration.LOOKOUT, Entity.THREAT)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.lookout_threat_event_timestamp,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.event_timestamp,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.NETSKOPE, Entity.THREAT)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.netskope_threat_event_timestamp,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.event_timestamp,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.PRADEO, Entity.THREAT)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.pradeo_threat_event_timestamp,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.event_timestamp,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.WANDERA, Entity.THREAT)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.wandera_threat_event_timestamp,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.event_timestamp,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.ZIMPERIUM, Entity.THREAT)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.zimperium_threat_event_timestamp,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.event_timestamp,
          reverse: true,
        }),
      ],
    },
    [getUniqueId(Integration.ZSCALER, Entity.THREAT)]: {
      V2: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.zscaler_threat_event_timestamp,
          reverse: true,
        }),
      ],
      V1: [
        new SortOn({
          by: COLUMN_NAMES.byName.event_timestamp,
          reverse: true,
        }),
      ],
    },
  };

  public static DRILLDOWN_TABLE_CONDITION_TYPE = {
    [DataType[DataType.STRINGLIST]]: FilterRule.FILTER_CONDITION.constainsAnyOf,
    [DataType[DataType.NUMBERLIST]]: FilterRule.FILTER_CONDITION.constainsAnyOf,
  };

  private static DPA_PREFIX = 'dpa';

  /**
   * getCveNistLink
   * @param {string} cveId
   * @returns {string}
   */
  public static getCveNistLink(cveId: string): string {
    return `https://nvd.nist.gov/vuln/detail/${cveId}`;
  }

  /**
   * getCveMicrosoftLink
   * @param {string} cveId
   * @returns {string}
   */
  public static getCveMicrosoftLink(cveId: string): string {
    return `https://portal.msrc.microsoft.com/en-US/security-guidance/advisory/${cveId}`;
  }

  /**
   * getHealthColorSet
   * @param {string} score
   * @returns {GenericObject}
   */
  public static getHealthColorSet(score: string): GenericObject {
    return DashboardConfig.poorNeutralGoodColors.find((color) => color.name === score) || DashboardConfig.unknownColorSet;
  }

  /**
   * getHealthColorByName
   * @returns {GenericObject}
   */
  public static getHealthColorByName(): GenericObject {
    return DashboardConfig.poorNeutralGoodColors.reduce((accumulator, value) => {
      accumulator[value.name] = value.value;
      return accumulator;
    }, {});
  }

  /**
   * getRiskColorSet
   * @param {string} score
   * @returns {GenericObject}
   */
  public static getRiskColorSet(score: string): GenericObject {
    return DashboardConfig.highMediumLowColors.find((color) => color.name === score) || DashboardConfig.unknownColorSet;
  }

  /**
   * getIconClassByPlatform
   * @param {string} platform
   * @returns {string}
   */
  public static getIconClassByPlatform(platform: string): string {
    return [DashboardConfig.DPA_PREFIX, this.iconClassByPlatform[platform]].join('-');
  }
}
