/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, GenericObject, JsonProperty, Serializable, serialize } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters/date-format.converter';
import { UserTouchDetails } from '@ws1c/intelligence-models/user-touch-details.model';
import { DeemPlaybookComponentConfig } from './deem-playbook-component.config';
import { DeemPlaybookStepItemBase } from './deem-playbook-step-items/deem-playbook-step-item-base.model';

/**
 * componentConverter
 * @type {CustomConverter}
 */
const componentConverter: CustomConverter = {
  fromJson(data: GenericObject[]): DeemPlaybookStepItemBase[] {
    return data
      ?.map((component: DeemPlaybookStepItemBase) => {
        const model = DeemPlaybookComponentConfig.stepComponentTypeToModel[component?.type];
        if (!model) {
          return;
        }
        return deserialize<DeemPlaybookStepItemBase>(model, component);
      })
      .filter((deserializedComponent: DeemPlaybookStepItemBase) => deserializedComponent !== undefined);
  },
  toJson(data: DeemPlaybookStepItemBase[]): GenericObject[] {
    return data?.map(serialize);
  },
};

/**
 * DeemPlaybookStep
 * @export
 * @class DeemPlaybookStep
 * @extends {UserTouchDetails}
 */
@Serializable
export class DeemPlaybookStep extends UserTouchDetails {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('step_id')
  public stepId: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('step_order')
  public stepOrder: number = undefined;

  @JsonProperty('source_object_id')
  public sourceObjectId: string = undefined;

  @JsonProperty('source_object_type')
  public sourceObjectType: string = undefined;

  @JsonProperty({ name: 'components', customConverter: componentConverter })
  public components: DeemPlaybookStepItemBase[] = undefined;

  @JsonProperty('open_duration')
  public openDuration: number = undefined;

  @JsonProperty('average_open_duration_millis')
  public averageOpenDurationMillis: number = undefined;

  @JsonProperty({ name: 'last_visited_at', customConverter: dateFormatConverter })
  public lastVisitedAt: number = undefined;

  /**
   * constructor
   * @param {Partial<DeemPlaybookStep>[]} args
   */
  constructor(...args: Array<Partial<DeemPlaybookStep>>) {
    super();
    Object.assign(this, ...args);
  }
}
